import * as React from "react"
import { Layout } from "../components/layout"
import { Card, Container } from "react-bootstrap"
import { Seo } from "../components/seo"

export default function ProductenOpMaat() {
  return (
    <Layout>
      <Container className="pt-7 pb-4">
        <h1 className="display-2 fw-bold text-center lh-1 mb-6">
          Private label<br/>
          <span className="text-center h3">Een persoonlijke traktatie voor je gasten, klanten of medewerkers</span>
        </h1>
        <h2>B&B/Logies</h2>
        <p>
          Verras je gasten aan de ontbijttafel met je signatuur granola of confituur. Een uniek recept dat, volledig in
          samenspraak met jou, werd samengesteld en exclusief bij jou te proeven (en/of te kopen) valt. Neem
          vrijblijvend contact op voor meer info.
        </p>
        <h2>Bedrijven</h2>
        <p>
          Op zoek naar een relatie- of eindejaarsgeschenk dat niet alleen lekker, maar ook gezond en verantwoord is?
          Met je eigen, unieke granola of confituur laat je alvast een smakelijke indruk na! Neem vrijblijvend contact
          op voor meer info.
        </p>
        <h2>Professionals</h2>
        <p>
          Ben je zelf actief als diëtist, voedingsconsulent, of -coach? Met je private label granola geef je je
          cliënten een gezond, lekker én energierijk duwtje in de rug. Vraag vrijblijvend naar alle mogelijkheden.
        </p>
        <h2>Relatiegeschenken</h2>
        <p>
          Zoek je een geschenk voor je zakenpartners? Of wil je je medewerkers eens goed in de bloemetjes zetten? Wat
          de gelegenheid ook is, begin maar alvast te watertanden bij het ruime aanbod confituren, granola’s, likeuren
          en advocaat. Een geschenkmand gevuld met dit lekkers, daar wordt toch iedereen blij van? Neem gerust contact
          op voor alle mogelijkheden.
        </p>
      </Container>
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="Ambachtelijke streekproducten voor bedrijven"
    description="Je zakenrelaties trakteren op lekkere, gezonde producten van lokale teelt? Dat is de mooiste blijk van waardering!"
  />
)
